import { IS_PRODUCTION } from '../../../utils/environment-constants';
import { getAPI } from '../helpers';
import { APIResponse } from '../types';

type OpeningHour = {
    from: string;
    to: string;
};

export type CarSaverWorkshop = {
    address: string;
    city: string;
    description: string;
    email: string;
    id: number;
    latitude: number;
    longitude: number;
    name: string;
    onlineBooking: string;
    phone: string;
    postalCode: string;
    website: string;
    openingHours: {
        monday: OpeningHour | null;
        tuesday: OpeningHour | null;
        wednesday: OpeningHour | null;
        thursday: OpeningHour | null;
        friday: OpeningHour | null;
        saturday: OpeningHour | null;
        sunday: OpeningHour | null;
    };
};

export const getCarSaverWorkshops = async (): APIResponse<CarSaverWorkshop[]> => {
    const apiUrl = IS_PRODUCTION ? 'https://api.carsaver.dk/v1/workshops' : 'https://app-carsaver-development.azurewebsites.net/v1/workshop';
    return getAPI<CarSaverWorkshop[]>(apiUrl);
};
